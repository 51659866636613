import { i18n } from '@/plugins/i18n'
import refreshToken from '@/plugins/refresh-token'
import { api } from './mainNode'



const block1 = async range => {
  //await refreshToken()
  const response = await api
    .get({
      path: 'main/block1',
      param: `${range}/${i18n.locale}`,
    })
    .then(res => res.data)
    .catch(err => {
      console.log('get dashboard err : ', err)

      return {}
    })

  return response
}
const block3 = async () => {
  //await refreshToken()
  const response = await api
    .get({
      path: 'main/block3',
      param: `${i18n.locale}`,
    })
    .then(res => res.data)
    .catch(err => {
      console.log('get dashboard err : ', err)

      return {}
    })

  return response
}
const block4 = async () => {
  //await refreshToken()
  const response = await api
    .get({
      path: 'main/block4',
      param: `${i18n.locale}`,
    })
    .then(res => res)
    .catch(err => {
      console.log('get dashboard err : ', err)

      return {}
    })

  return response
}
export default { block1, block3, block4 }
