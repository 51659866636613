<template>
  <div>
    <v-skeleton-loader v-if="!statistics" class="mx-auto" max-height="115" type="card"></v-skeleton-loader>
    <v-card v-else>
      <div class="d-flex">
        <div>
          <v-card-title class="text-base font-weight-semibold">
            <router-link
              :to="{
                name: 'Receipt Summary',
              }"
              class="primary--text text-decoration-none"
              target="_blank"
            >
              {{ $t('today_sales') }}
            </router-link>
          </v-card-title>
          <v-card-text class="d-flex align-center">
            <h3 class="text-xl">
              {{ statistics }}
            </h3>
            <span class="text-xs ms-1" :class="checkChange(change) ? 'success--text' : 'error--text'">
              {{ change }}</span
            >
          </v-card-text>
        </div>
        <v-spacer></v-spacer>
        <div class="illustrator-img">
          <v-img :width="avatarWidth" :src="avatar"></v-img>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    statTitle: { type: String, default: '' },
    statistics: { type: String, default: '' },
    change: { type: String, default: '' },
    chipText: { type: String, default: '' },
    chipColor: { type: String, default: '' },
    avatar: { type: String, default: '' },
    avatarWidth: { type: String, default: '' },
  },
  setup() {
    const checkChange = value => {
      const firstChar = value.charAt(0)
      if (firstChar === '+') {
        return true
      }

      return false
    }

    return { checkChange }
  },
}
</script>

<style lang="scss" scoped>
.illustrator-img {
  position: absolute;
  right: 0;
  bottom: 0;
}

.v-application {
  &.v-application--is-rtl {
    .illustrator-img {
      left: 5%;
      right: unset;
    }
  }
}
</style>
