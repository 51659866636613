<template>
  <div>
    <v-skeleton-loader
      v-if="loading || loadingCart"
      type="image, image"
      max-height="300"
      tile
    ></v-skeleton-loader>

    <v-card
      v-else
      min-height="220"
    >
      <v-card-title class="align-start">
        <span>  {{ $t("data_use_services") }} <small>{{ $t("check_use_servuce") }}</small></span>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="7"
          >
            <vue-apex-charts
              id="chart-sales-overview"
              :options="chartOptions"
              :series="chartData"
              class="h-full d-flex align-center"
            ></vue-apex-charts>
          </v-col>
          <v-col
            cols="12"
            md="5"
          >
            <table class="sales-overview-stats-table">
              <tr>
                <td class="py-0">
                  <div class="mb-0">
                    <!-- <div
                      :style="`background-color:${$vuetify.theme.currentTheme.success};`"
                      class="stats-dot  d-inline-block rounded-circle me-2 "
                    ></div> -->
                    <v-icon
                      small
                      class="me-2"
                      :color="`${$vuetify.theme.currentTheme.success}`"
                    >
                      {{ icons.mdiStethoscope }}
                    </v-icon>
                    <span>{{ $t('check') }}</span>
                    <span class="text-base text--primary ms-1">{{ chartData[0] }}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="py-0">
                  <div class="mb-0">
                    <!-- <div
                      style="background-color:#F4511E"
                      class="stats-dot1  d-inline-block rounded-circle me-2"
                    ></div> -->
                    <v-icon
                      small
                      class="me-2"
                      color="#F4511E"
                    >
                      {{ icons.mdiTicketAccount }}
                    </v-icon>
                    <span>{{ $t('use_service') }}</span>
                    <span class="text-base text--primary  ms-1">{{ chartData[1] }}</span>
                  </div>
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { i18n } from '@/plugins/i18n'
import { addAlpha, getVuetify } from '@core/utils'
import {
  mdiCurrencyUsd, mdiDotsVertical, mdiTicketAccount, mdiStethoscope,
} from '@mdi/js'
import VueApexCharts from 'vue-apexcharts'
import { ref, watch } from '@vue/composition-api'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const $vuetify = getVuetify()
    const colorList = [$vuetify.theme.currentTheme.success, '#F4511E']
    const loadingCart = ref(false)
    const chartOptions = ref({
      labels: [i18n.t('check'), i18n.t('use_service')],
      colors: colorList,
      chart: {
        offsetY: -10,
        type: 'donut',
        sparkline: {
          enabled: true,
        },
      },
      stroke: {
        show: true,
        curve: ['smooth'],
        lineCap: 'butt',
        colors: $vuetify.theme.isDark ? '#fff' : '#757575',
        width: 2,
        dashArray: 6,
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: true,
        textAnchor: 'middle',
        style: {
          fontSize: '14px',
          fontFamily: 'Sarabun',
          fontWeight: 'bold',
          colors: undefined,
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 2,
          borderColor: $vuetify.theme.isDark ? '#fff' : '#212121',
          opacity: 0.9,
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 1,
          opacityTo: 1,
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          inverseColors: true,

          // stops: [0, 100],

          colorStops: [
            [
              {
                offset: 0,
                color: addAlpha(colorList[0], 0.4),
                opacity: 0.1,
              },
              {
                offset: 50,
                color: addAlpha(colorList[0], 0.7),
                opacity: 0.5,
              },
              {
                offset: 100,
                color: colorList[0],
                opacity: 1,
              },

            ],
            [
              {
                offset: 0,
                color: addAlpha(colorList[1], 0.3),
                opacity: 0.1,
              },
              {
                offset: 50,
                color: addAlpha(colorList[1], 0.7),
                opacity: 0.5,
              },
              {
                offset: 100,
                color: colorList[1],
                opacity: 1,
              },
            ],
          ],

        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: '70%',
            background: 'transparent',
            labels: {
              show: true,
              name: {
                offsetY: 25,
              },
              value: {
                offsetY: -15,
                formatter(value) {
                  return `${value} `
                },
              },
              total: {
                show: true,
                label: i18n.t('all'),
                color: 'rgba(94, 86, 105, 0.68)',
                formatter(value) {
                  return `${value.globals.seriesTotals.reduce((total, num) => total + num)}`
                },
              },
            },
          },
        },
      },

    })
    watch(() => $vuetify.theme.isDark, value => {
      loadingCart.value = true
      if (value) {
        chartOptions.value.stroke.colors = '#fff'
        chartOptions.value.dataLabels.background.borderColor = '#fff'
        chartOptions.value.fill.gradient.colorStops[0][0].color = colorList[0]
        chartOptions.value.fill.gradient.colorStops[0][1].color = colorList[0]
        chartOptions.value.fill.gradient.colorStops[1][0].color = colorList[1]
        chartOptions.value.fill.gradient.colorStops[1][1].color = colorList[1]
      } else {
        chartOptions.value.stroke.colors = '#212121'
        chartOptions.value.dataLabels.background.borderColor = '#212121'
        chartOptions.value.fill.gradient.colorStops[0][0].color = addAlpha(colorList[0], 0.4)
        chartOptions.value.fill.gradient.colorStops[0][1].color = addAlpha(colorList[0], 0.7)
        chartOptions.value.fill.gradient.colorStops[1][0].color = addAlpha(colorList[1], 0.4)
        chartOptions.value.fill.gradient.colorStops[1][1].color = addAlpha(colorList[1], 0.7)
      }
      setTimeout(() => {
        loadingCart.value = false
      }, 50)
    })

    return {
      chartOptions,
      loadingCart,
      icons: {
        mdiDotsVertical,
        mdiCurrencyUsd,
        mdiTicketAccount,
        mdiStethoscope,
      },
    }
  },
}
</script>

<style lang="scss">
#chart-sales-overview {
  .apexcharts-canvas {
    .apexcharts-text {
      &.apexcharts-datalabel-value {
        font-weight: 300;
      }
      &.apexcharts-datalabel-label {
        font-size: 1rem;
      }
    }
  }
}

.sales-overview-stats-table {
  width: 100%;
  td {
    padding-bottom: 1rem;
  }

  .stats-dot {
    padding: 0.33rem;
  }
  .stats-dot1 {
    padding: 0.33rem;
  }

  // Set opacity of dots
  // tr {
  //   &:nth-of-type(1) {
  //     td:nth-of-type(2) {
  //       .stats-dot {
  //         opacity: 0.7;
  //       }
  //     }
  //   }
  //   &:nth-of-type(2) {
  //     td:nth-of-type(1) {
  //       .stats-dot {
  //         opacity: 0.5;
  //       }
  //     }
  //     td:nth-of-type(2) {
  //       .stats-dot {
  //         opacity: 0.15;
  //       }
  //     }
  //   }
  // }
}
</style>
