<template>
  <div>
    <v-skeleton-loader
      v-if='!statistics'
      class='mx-auto'
      max-height='115'
      type='card'
    ></v-skeleton-loader>
    <v-card v-else>
      <div class='d-flex'>
        <div>
          <v-card-title class='text-base font-weight-semibold'>
            <router-link
              class='font-weight-medium text-decoration-none'
              :to="{ name: 'invoicesummary'}"
              target='_blank'
            >
              <span>{{ $t('wait_for_payment') }}</span>
            </router-link>
          </v-card-title>
          <v-card-text class='d-flex align-center'>
            <h3 class='text-xl'>
              {{ statistics }} {{ currency == 2 ? $t('USD') : $t('THB') }}
            </h3>
          </v-card-text>
        </div>
        <v-spacer></v-spacer>
        <div class='illustrator-img'>
          <v-img
            :width='avatarWidth'
            :src='avatar'
          ></v-img>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    currency: { type: String, default: '' },
    statistics: { type: String, default: '' },
    avatar: { type: String, default: '' },
    avatarWidth: { type: String, default: '' },
  },
  setup() {
    const checkChange = value => {
      const firstChar = value.charAt(0)
      if (firstChar === '+') {
        return true
      }

      return false
    }

    return { checkChange }
  },
}
</script>

<style lang='scss' scoped>
.illustrator-img {
  position: absolute;
  right: 0;
  bottom: -10%;
}

.v-application {
  &.v-application--is-rtl {
    .illustrator-img {
      left: 5%;
      right: unset;
    }
  }
}
</style>
