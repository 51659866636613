import dashboard from '@/api/dashboard'
import menu from '@/api/menu'
import profile from '@/api/profile'
import { formatPrice } from '@/plugins/filters'
import { i18n } from '@/plugins/i18n'
import { onMounted, ref, watch } from '@vue/composition-api'
import refreshToken from '@/plugins/refresh-token'
import shop from '@/api/shop'

export default function useDashboard() {
  const roleId = ref('')
  const showDataBlock1 = ref(true)
  const showDataBlock2 = ref(true)
  const showDataBlock3 = ref(true)
  const showDataBlock4 = ref(true)
  const block1Status = ref(false)
  const block2Status = ref(false)
  const block3Status = ref(false)
  const block4Status = ref(false)
  const block5Status = ref(false)
  const block6Status = ref(false)
  const block7Status = ref(false)
  const block8Status = ref(false)
  const loading = ref(true)
  const currentCurrency = ref('')
  const loadingLine = ref(true)
  const countDay = ref('1')
  const saleDaily = ref({})
  const saleMonth = ref({})
  const saleYear = ref({})
  const checkAndService = ref([])
  const saleAll = ref([])
  const feeCost = ref([])
  const customerAmount = ref({})
  const doctorCost = ref({})
  const staffCost = ref({})
  const waitPayment = ref({})
  const accountArrears = ref({})
  const drugEquipmentAll = ref({})
  const drugEquipmentExp = ref({})
  const drugEquipmentLowStock = ref({})
  const shopName = ref('')

  const commission = ref({})
  const chartDataLine = ref([
    { name: '', data: [], type: 'column' },
    { name: '', data: [], type: 'line' },
  ])
  shop.shop().then(res => {
    shopName.value = res.shop_name
  })

  profile().then(res => {
    roleId.value = res.role_id
    showDataBlock1.value = res.user_dashboard_1 == '0'
    showDataBlock2.value = res.user_dashboard_2 == '0'
    showDataBlock3.value = res.user_dashboard_3 == '0'
    showDataBlock4.value = res.user_dashboard_4 == '0'
  })
  menu().then(res => {
    const menuList = res.find(item => item.group_menu_id == '13')
    for (let i = 0; i < menuList.menu.length; i++) {
      if (menuList.menu[i].menu_link == 'reportorderpay') {
        block2Status.value = true
      }
      if (menuList.menu[i].menu_link == 'reportdrugbalance') {
        block3Status.value = true
      }
      if (menuList.menu[i].menu_link == 'reportexpired') {
        block4Status.value = true
      }
      if (menuList.menu[i].menu_link == 'reportdruglowest') {
        block5Status.value = true
      }
      if (menuList.menu[i].menu_link == 'reportcommis') {
        block6Status.value = true
      }
      if (menuList.menu[i].menu_link == 'reportdoctor') {
        block7Status.value = true
      }
      if (menuList.menu[i].menu_link == 'reportuser') {
        block8Status.value = true
      }
    }
    block1Status.value = true
  })
  onMounted(async () => {

    await refreshToken()
    getBlock1('day')
    getBlock3()
    setTimeout(() => {
      currentCurrency.value = localStorage.getItem('currency')
    }, 1000)
  })
  const { block1, block3, block4 } = dashboard

  const getBlock1 = value => {
    block1(value).then(res => {
      checkAndService.value = [+res.chart_data.count_opd, +res.chart_data.count_serve]
      feeCost.value = [+res.chart_data.total_dortor, +res.chart_data.total_user]
      saleAll.value = [+res.chart_data.total_drug, +res.chart_data.total_equipment, +res.chart_data.total_course]
      doctorCost.value = {
        statistics: formatPrice(res.chart_data.total_dortor),
        avatar: require('@/assets/images/3d-characters/dashboard/Y7.png'),
        avatarWidth: '60',
      }
      staffCost.value = {
        statistics: formatPrice(res.chart_data.total_user),
        avatar: require('@/assets/images/3d-characters/dashboard/Y8.png'),
        avatarWidth: '40',
      }
      saleDaily.value = {
        statTitle: i18n.t('today_sales'),
        statistics: res.sum_receipt_today,
        avatar: require('@/assets/images/3d-characters/dashboard/3D1.png'),
        avatarWidth: '75',
      }
      saleMonth.value = {
        statTitle: i18n.t('sales_this_month'),
        statistics: res.sum_receipt_month,
        avatar: require('@/assets/images/3d-characters/dashboard/3D5.png'),
        avatarWidth: '80',
      }
      saleYear.value = {
        statTitle: i18n.t('sales_this_year'),
        statistics: res.sum_receipt_year,
        avatar: require('@/assets/images/3d-characters/dashboard/48.png'),
        avatarWidth: '80',
      }
      customerAmount.value = {
        statTitle: i18n.t('customer_amount'),
        statistics: res.count_customer.toString(),
        avatar: require('@/assets/images/3d-characters/dashboard/3D4.png'),
        avatarWidth: '85',
      }
      loading.value = false
    })
  }

  watch(countDay, value => {
    loading.value = true
    let type = 'day'
    if (value == '1') {
      type = 'day'
    } else if (value == '2') {
      type = 'mounth'
    } else {
      type = 'year'
    }
    getBlock1(type)
    getBlock3()
  })

  block4().then(res => {
    chartDataLine.value[0].name = res.year[1].toString()
    chartDataLine.value[1].name = res.year[0].toString()
    chartDataLine.value[0].data = res.data.newyear
    chartDataLine.value[1].data = res.data.oldyear
    loadingLine.value = false
  })

  const getBlock3 = () => {
    block3().then(res => {
      const valueCom = ref('')
      if (countDay.value == '1') {
        valueCom.value = res.day.total_commission
      } else if (countDay.value == '2') {
        valueCom.value = res.month.total_commission
      } else if (countDay.value == '3') {
        valueCom.value = res.year.total_commission
      } else {
        valueCom.value = res.all.total_commission
      }
      waitPayment.value = {
        statistics: res.receipt_pay,
        avatar: require('@/assets/images/3d-characters/dashboard/Y5.png'),
        avatarWidth: '80',
      }
      accountArrears.value = {
        statistics: res.pay_balance,
        avatar: require('@/assets/images/3d-characters/dashboard/Y4.png'),
        avatarWidth: '50',
      }
      drugEquipmentAll.value = {
        statistics: res.drugordert_total,
        avatar: require('@/assets/images/3d-characters/dashboard/Y1.png'),
        avatarWidth: '70',
      }
      drugEquipmentExp.value = {
        statistics: res.drug_expired,
        avatar: require('@/assets/images/3d-characters/dashboard/Y3.png'),
        avatarWidth: '100',
      }
      drugEquipmentLowStock.value = {
        statistics: formatPrice(res.drug_lowest),
        avatar: require('@/assets/images/3d-characters/dashboard/Y2.png'),
        avatarWidth: '70',
      }
      commission.value = {
        statistics: valueCom.value,
        avatar: require('@/assets/images/3d-characters/dashboard/Y6.png'),
        avatarWidth: '60',
      }
    })
  }

  return {
    roleId,
    block1Status,
    block2Status,
    block3Status,
    block4Status,
    block5Status,
    block6Status,
    block7Status,
    block8Status,
    showDataBlock1,
    showDataBlock2,
    showDataBlock3,
    showDataBlock4,
    commission,
    drugEquipmentLowStock,
    drugEquipmentExp,
    drugEquipmentAll,
    accountArrears,
    doctorCost,
    staffCost,
    waitPayment,
    loadingLine,
    chartDataLine,
    feeCost,
    saleAll,
    loading,
    checkAndService,
    countDay,
    currentCurrency,
    customerAmount,
    saleYear,
    saleMonth,
    saleDaily,
    shopName,
  }
}
