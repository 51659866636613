<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      type="card"
      tile
    ></v-skeleton-loader>

    <v-card v-else>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
          >
            <vue-apex-charts
              id="chart-sales-overview"
              :type="lineCart.chart.type"
              :options="lineCart"
              :series="chartData"
              class="h-full d-flex align-center"
            ></vue-apex-charts>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { formatPrice } from '@/plugins/filters'
import { i18n } from '@/plugins/i18n'
import { addAlpha, getVuetify } from '@core/utils'
import { mdiCurrencyUsd, mdiDotsVertical } from '@mdi/js'
import { ref } from '@vue/composition-api'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    chartData: {
      type: Array,
      default: [
        { name: '', data: [] },
        { name: '', data: [] },
      ],
    },
    loading: { type: Boolean, default: true },
  },
  setup() {
    const $vuetify = getVuetify()

    const lineCart = ref({
      chart: {
        height: 430,
        type: 'line',
        fontFamily: 'Kanit',
        labels: {
          show: true,
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false | '<img src="/static/icons/reset.png" width="20">',
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString()
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: 'zoom',
        },
      },
      stroke: {
        width: [1, 4],
        curve: 'smooth',
        lineCap: '#212121',
      },
      title: {
        text: i18n.t('sales_comparison_chart'),
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
        textAnchor: 'middle',
        distributed: false,
        formatter(val) {
          return formatPrice(val)
        },
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '16px',
          fontFamily: 'kanit',
          fontWeight: 'bold',
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#212121',
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            opacity: 0.45,
          },
        },
      },
      labels: [
        i18n.t('jan'),
        i18n.t('feb'),
        i18n.t('mar'),
        i18n.t('apr'),
        i18n.t('may'),
        i18n.t('jun'),
        i18n.t('jul'),
        i18n.t('aug'),
        i18n.t('sep'),
        i18n.t('oct'),
        i18n.t('nov'),
        i18n.t('dec'),
      ],
      yaxis: {
        labels: {
          show: true,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: true,
          minHeight: undefined,
          maxHeight: 120,
          formatter(value) {
            return formatPrice(value)
          },
          style: {
            fontSize: '12px',
            fontFamily: 'Kanit',
            fontWeight: 'bold',
            cssClass: 'apexcharts-xaxis-label',
          },
          offsetX: 0,
          offsetY: 0,
        },
        title: {
          text: i18n.t('cost_amount'),
        },
      },
      xaxis: {
        show: true,
        rotateAlways: true,
        hideOverlappingLabels: true,
        showDuplicates: false,
        trim: false,
        minHeight: undefined,
        maxHeight: 120,
        labels: {
          show: true,
          rotate: -90,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: true,
          trim: false,
          minHeight: undefined,
          maxHeight: 120,
          style: {
            fontSize: '12px',
            fontFamily: 'Kanit',
            fontWeight: 400,
          },
          offsetX: 0,
          offsetY: 0,
        },
      },
      colors: ['#80DEEA', '#F8BBD0'],
      fill: {
        colors: ['#0097A7', '#F8BBD0'],
        type: 'gradient',
        gradient: {
          shadeIntensity: 0.5,
          colorStops: [
            [
              {
                offset: 0,
                color: '#80DEEA',
                opacity: 0.7,
              },
              {
                offset: 90,
                color: '#0097A7',
                opacity: 1,
              },
            ],
            [
              {
                offset: 100,
                color: '#F8BBD0',
                opacity: 1,
              },
              {
                offset: 100,
                color: '#F8BBD0',
                opacity: 1,
              },
            ],
          ],
        },
      },
      legend: {
        show: true,
        fontSize: '18px',
        fontFamily: 'Kanit',
        fontWeight: 'bold',
        markers: {
          width: 14,
          height: 14,
          strokeWidth: 0,
          strokeColor: '#212121',
          radius: 14,
          offsetX: -1,
          offsetY: 0,
        },
      },
    })
    const chartOptions = {
      labels: [i18n.t('drug'), i18n.t('equipment'), i18n.t('course')],
      colors: [
        $vuetify.theme.currentTheme.warning,
        addAlpha($vuetify.theme.currentTheme.warning, 0.7),
        addAlpha($vuetify.theme.currentTheme.warning, 0.3),
      ],
      chart: {
        type: 'line',
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: '70%',
            labels: {
              show: true,
              name: {
                fontSize: '14px',
                offsetY: 25,
              },

              value: {
                fontSize: '2.125rem',
                fontWeight: 600,
                offsetY: -15,
                formatter(value) {
                  return `${value}`
                },
              },
              total: {
                show: true,
                label: i18n.t('total_'),
                color: 'rgba(94, 86, 105, 0.68)',
                formatter(value) {
                  return `${value.globals.seriesTotals.reduce((total, num) => parseFloat((total + num).toFixed(2)))}`
                },
              },
            },
          },
        },
      },
    }

    return {
      chartOptions,
      lineCart,
      icons: {
        mdiDotsVertical,
        mdiCurrencyUsd,
      },
    }
  },
}
</script>

<style lang="scss">
#chart-sales-overview {
  .apexcharts-canvas {
    .apexcharts-text {
      &.apexcharts-datalabel-value {
        font-weight: 600;
      }
      &.apexcharts-datalabel-label {
        font-size: 1rem;
      }
    }
  }
}

.sales-overview-stats-table {
  width: 100%;
  td {
    padding-bottom: 1rem;
  }

  .stats-dot {
    padding: 0.33rem;
  }

  // Set opacity of dots
  tr {
    &:nth-of-type(1) {
      td:nth-of-type(2) {
        .stats-dot {
          opacity: 0.7;
        }
      }
    }
    &:nth-of-type(2) {
      td:nth-of-type(1) {
        .stats-dot {
          opacity: 0.5;
        }
      }
    }
    &:nth-of-type(3) {
      td:nth-of-type(1) {
        .stats-dot {
          opacity: 0.3;
        }
      }
    }
  }
}
</style>
