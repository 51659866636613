<template>
  <div>
    <v-row v-if='!showDataBlock1 && !showDataBlock2 && !showDataBlock3 && !showDataBlock4'>
      <v-col cols='12'>
        <NotAuthorized />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col v-if='showDataBlock2 || showDataBlock3' cols='12' md='6'>
        <v-btn-toggle v-model='countDay' color='primary' mandatory>
          <v-btn depressed value='1'>
            {{ $t('daily') }}
          </v-btn>
          <v-btn depressed value='2'>
            {{ $t('monthly') }}
          </v-btn>

          <v-btn depressed value='3'>
            {{ $t('yearly') }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col v-if='showDataBlock2 || showDataBlock3' cols='12' md='6' class='text-end'>
        <span class='font-weight-bold text-h6'>{{ shopName }}</span>
      </v-col>
      <v-col v-if='showDataBlock1' cols='12' md='6' lg='3' class='align-self-end'>
        <SaleDaily
          :avatar='saleDaily.avatar'
          :avatar-width='saleDaily.avatarWidth'
          :statistics='saleDaily.statistics'
          :stat-title='saleDaily.statTitle'
        ></SaleDaily>
      </v-col>
      <v-col v-if='showDataBlock1' cols='12' md='6' lg='3' class='align-self-end'>
        <SaleMonth
          :avatar='saleMonth.avatar'
          :avatar-width='saleMonth.avatarWidth'
          :statistics='saleMonth.statistics'
          :stat-title='saleMonth.statTitle'
        ></SaleMonth>
      </v-col>
      <v-col v-if='showDataBlock1' cols='12' md='6' lg='3'>
        <SaleYear
          :avatar='saleYear.avatar'
          :avatar-width='saleYear.avatarWidth'
          :statistics='saleYear.statistics'
          :stat-title='saleYear.statTitle'
        ></SaleYear>
      </v-col>
      <v-col v-if='showDataBlock1' cols='12' md='6' lg='3' class='align-self-end'>
        <CustomerAmount
          :avatar='customerAmount.avatar'
          :avatar-width='customerAmount.avatarWidth'
          :statistics='customerAmount.statistics'
          :stat-title='customerAmount.statTitle'
        ></CustomerAmount>
      </v-col>

      <v-col v-if='showDataBlock2' cols='12' md='6' lg='4'>
        <CheckListLasted :chart-data='checkAndService' :loading='loading' />
      </v-col>

      <v-col v-if='showDataBlock2' cols='12' md='6' lg='4'>
        <SaleAll :chart-data='saleAll' :loading='loading' />
      </v-col>

      <v-col v-if='showDataBlock2' cols='12' md='6' lg='4'>
        <Fee :chart-data='feeCost' :loading='loading' />
      </v-col>
      <v-col v-if='showDataBlock4' cols='12' md='6'>
        <SaleCompare :chart-data='chartDataLine' :loading='loadingLine' />
      </v-col>

      <v-col v-if='showDataBlock3' cols='12' lg='6'>
        <v-row>
          <v-col v-if='block1Status' cols='12' md='6'>
            <WaitPayment
              :currency='currentCurrency'
              :avatar='waitPayment.avatar'
              :avatar-width='waitPayment.avatarWidth'
              :statistics='waitPayment.statistics'
            />
          </v-col>
          <v-col v-if='block2Status' cols='12' md='6'>
            <AccountArrears
              :currency='currentCurrency'
              :avatar='accountArrears.avatar'
              :avatar-width='accountArrears.avatarWidth'
              :statistics='accountArrears.statistics'
            />
          </v-col>
          <v-col v-if='block3Status' cols='12' md='6'>
            <DrugEquipmentAll
              :avatar='drugEquipmentAll.avatar'
              :avatar-width='drugEquipmentAll.avatarWidth'
              :statistics='drugEquipmentAll.statistics'
            />
          </v-col>
          <v-col v-if='block4Status' cols='12' md='6'>
            <DrugEquipmentEXP
              :avatar='drugEquipmentExp.avatar'
              :avatar-width='drugEquipmentExp.avatarWidth'
              :statistics='drugEquipmentExp.statistics'
            />
          </v-col>
          <v-col v-if='block5Status' cols='12' md='6'>
            <DrugEquipmentLowStock
              :avatar='drugEquipmentLowStock.avatar'
              :avatar-width='drugEquipmentLowStock.avatarWidth'
              :statistics='drugEquipmentLowStock.statistics'
            />
          </v-col>
          <v-col v-if='block6Status' cols='12' md='6'>
            <Commission
              :currency='currentCurrency'
              :avatar='commission.avatar'
              :avatar-width='commission.avatarWidth'
              :statistics='commission.statistics'
            />
          </v-col>
          <v-col v-if='block7Status' cols='12' md='6'>
            <CostDoctor
              :currency='currentCurrency'
              :avatar='doctorCost.avatar'
              :avatar-width='doctorCost.avatarWidth'
              :statistics='doctorCost.statistics'
            />
          </v-col>
          <v-col v-if='block8Status' cols='12' md='6'>
            <CostStaff
              :currency='currentCurrency'
              :avatar='staffCost.avatar'
              :avatar-width='staffCost.avatarWidth'
              :statistics='staffCost.statistics'
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AccountArrears from '@core/components/statistics-card/AccountArrears.vue'
import CheckListLasted from '@core/components/statistics-card/CheckListLasted.vue'
import Commission from '@core/components/statistics-card/Commission.vue'
import CostDoctor from '@core/components/statistics-card/CostDoctor.vue'
import CostStaff from '@core/components/statistics-card/CostStaff.vue'
import CustomerAmount from '@core/components/statistics-card/CustomerAmount.vue'
import DrugEquipmentAll from '@core/components/statistics-card/DrugEquipmentAll.vue'
import DrugEquipmentEXP from '@core/components/statistics-card/DrugEquipmentEXP.vue'
import DrugEquipmentLowStock from '@core/components/statistics-card/DrugEquipmentLowStock.vue'
import Fee from '@core/components/statistics-card/Fee.vue'
import SaleAll from '@core/components/statistics-card/SaleAll.vue'
import SaleCompare from '@core/components/statistics-card/SaleCompare.vue'
import SaleDaily from '@core/components/statistics-card/SaleDaily.vue'
import SaleMonth from '@core/components/statistics-card/SaleMonth.vue'
import SaleYear from '@core/components/statistics-card/SaleYear.vue'
import WaitPayment from '@core/components/statistics-card/WaitPayment.vue'

import NotAuthorized from '../NotAuthorized.vue'
import useDashboard from './useDashBoard'

export default {
  components: {
    CostStaff,
    CostDoctor,
    Commission,
    DrugEquipmentLowStock,
    DrugEquipmentEXP,
    DrugEquipmentAll,
    AccountArrears,
    WaitPayment,
    SaleCompare,
    Fee,
    CheckListLasted,
    SaleAll,
    SaleMonth,
    SaleYear,
    SaleDaily,
    CustomerAmount,
    NotAuthorized,
  },
  setup() {
    return {
      ...useDashboard(),
    }
  },
}
</script>
